/* 
Moduł odpowiedzialny za dostarczanie danych o firmach, ich usługach i dostępnych terminach
*/

// import { AppCarsService } from "../../api/api"

import { AppCarsService } from "@/api/api"

const state = {
  companies: [],
  companyAvailableDates: [],
  companyServices: {},
}

const getters = {
  getCompanies(state) {
    return state.companies
  },

  getCompanyById: state => companyId => {
    return state.companies.find(company => company.id === companyId)
  },

  getAvailableDates(state) {
    return state.companyAvailableDates
  },

  getCompanyServices(state) {
    return state.companyServices
  },
}

const mutations = {
  setCompanies(state, companies) {
    state.companies = companies
  },

  setAvailableDates(state, dates) {
    state.companyAvailableDates = dates
  },

  setCompanyServices(state, services) {
    const servicesAsObject = services.reduce((val, service) => {
      const serviceType = service.car_service_type.name

      if (!Object.prototype.hasOwnProperty.call(val, serviceType)) {
        val[serviceType] = []
      }

      val[serviceType].push(service)
      return val
    }, {})

    state.companyServices = servicesAsObject
  },
}

const actions = {
  async getCompanyServices(context, companySlug) {
    try {
      const token = context.rootGetters["auth/authToken"]
      const servicesRes = await AppCarsService.getCompanyServices(companySlug, token)

      if (servicesRes.status !== 200) {
        throw new Error("Nie udało się pobrać usług firmy")
      }

      context.commit("setCompanyServices", servicesRes.data.content)
    } catch (err) {
      return err.response || err
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
