// Moduł odpowiedzialny za zarządzanie danymi zalogowanej firmy
import { CompanyService } from "@/api/api"

const state = {
  profile: null,
}

const getters = {
  profile(state) {
    return state.profile
  },

  address(state) {
    return state.profile.address[0]
  },

  uid(state) {
    return state.profile.uid
  },

  name(state) {
    return state.profile.name
  },
}

const mutations = {
  setProfile(state, profile) {
    state.profile = profile
  },

  setAddress(state, address) {
    state.profile.address = [address]
  },

  setEmail(state, email) {
    state.profile.email = email
  },

  setPhone(state, phone) {
    state.profile.details.phone = phone
  },

  setBank(state,payload) {
    state.profile.details.bank_name = payload.name
    state.profile.details.bank_number = payload.number
  },

  setLogo(state, logo){
    //console.log(state, logo)
    state.profile.details.logo_url = logo
    //console.log(state.profile.details)
  }
}

const actions = {
  async updateLogo(context, payload){
    try{
      const response = await CompanyService.updateLogo(payload.id, payload.file, payload.token)
      return response.data.content
    }catch(error){
      return error.responmse || error
    }
  },
  async updateBankData(context, payload){
    try{
      const response = await CompanyService.updateBankData(payload.id, payload.bank, payload.token)
      if(response.data.content.status == 'success' ) {
        context.commit("setBank", {
          name: payload.bank.name,
          number: payload.bank.number
        })
        return true
      }
    }catch(error){
      return error.responmse || error
    }
  },


  async updateCompanyData(context, payload) {
    try {
      const slug = context.state.profile.slug
      const companyDataRes = await CompanyService.updateCompanyData(slug, payload)
      //console.log(companyDataRes)
      if (companyDataRes.status === 201) {
        context.commit("setProfile", companyDataRes.data.content.company)
        context.commit("setAddress", companyDataRes.data.content.address)
      }

      return companyDataRes
    } catch (error) {
      return error.response || error
    }
  },

  async updateAddress(context, addressForm) {
    try {
      const slug = context.state.profile.slug
      const addressRes = await CompanyService.updateAddress(slug, addressForm)

      if (addressRes.status === 201) {
        context.commit("setAddress", addressRes.data.content.address)
      }

      return addressRes
    } catch (error) {
      return error.response || error
    }
  },

  async updateContact(context, contactData) {
    try {
      const slug = context.state.profile.slug
      const contactRes = await CompanyService.updateContact(slug, contactData)

      if (contactRes.status === 201) {
        context.commit("setEmail", contactRes.data.content.company.email)
        context.commit("setPhone", contactRes.data.content.details.phone)
        context.commit("setLogo", contactRes.data.content.details.logo_url)
      }

      return contactRes
    } catch (error) {
      return error.response || error
    }
  },

  async changePassword(context, payload) {
    try {
      const response = await CompanyService.changePassword(payload)
      context.commit("")
      return response
    } catch (error) {
      console.error(`Błąd podczas zmiany hasła: ${error}`)
      return error
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
