<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {},
  computed: {
    ...mapGetters("auth", ["authToken", "account"]),
  },
  mounted() {
    this.$nextTick(() => {
      window.setInterval(() => {
        if (this.account == "user" || this.account == "administrator") {
          this.$store.dispatch("auth/renewToken").then((response) => {
            response;
            //console.log(response);
          });
        } else if (this.account == "company") {
          this.$store.dispatch("auth/renewCompanyToken").then((response) => {
            console.error(response);
            //console.log(response);
          });
        }
      }, 3000000);
      //55min 60 min token
    });
  },
};
</script>

<style lang="scss">
@import "@/scss/theme.scss";
</style>
