import Vue from "vue"
import axios from "axios"
import VueAxios from "vue-axios"
import API_URL from "@/config/config.js"
import Cookies from "js-cookie"

/*  jakwoz.eu/server/api/v1  */
export function authHeader(token) {
  Vue.axios.defaults.headers.Authorization = `Bearer ${token}`
}

/* obiekt odpowiedzialny za wywoływanie requestów do API */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = API_URL
    Vue.axios.defaults.headers.common = {
      "Content-Type": "application/json",
    }
    if (Cookies.get("token")) Vue.axios.defaults.headers.Authorization = `Bearer ${Cookies.get("token")}`
  },

  get(resource, params = "") {
    return Vue.axios.get(`${resource}/${params}`).catch(error => {
      throw new Error(`${error}`)
    })
  },

  getWithSlashEscape(resource, params = "") {
    return Vue.axios.get(`${resource}`, params).catch(error => {
      throw new Error(`${error}`)
    })
  },

  post(resource, params) {
    return Vue.axios.post(`${resource}`, params)
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params)
  },

  patch(resource, params) {
    return Vue.axios.patch(`${resource}`, params)
  },

  delete(resource) {
    return Vue.axios.delete(`${resource}`)
  },

  upload(resource, file, method = "post") {
    Vue.axios.defaults.headers.common = {
      "Content-Type": "multipart/form-data",
    }
    let formdata = new FormData()
    formdata.append("file", file)
    return Vue.axios[method](`${resource}`, formdata)
  },
}

export default ApiService

export const InsurenceApi = {
  getOne(insurenceId, myCarId) {
    return ApiService.get(`my-car-insurence/${insurenceId}/${myCarId}`)
  },
  getAll(myCarId, userId) {
    return ApiService.get(`my-car-insurence/${myCarId}/${userId}/all`)
  },
  add(payload) {
    return ApiService.post(`my-car-insurence/`, payload)
  },
  edit(insurenceId, payload) {
    return ApiService.patch(`my-car-insurence/${insurenceId}`, payload)
  },
  delete(insurenceId, myCarId, userId) {
    return ApiService.delete(`my-car-insurence/${insurenceId}/${myCarId}/${userId}`)
  }
}

export const TechnicalDiagnoseApi = {
  getOne(technicalDiagnoseId, myCarId) {
    return ApiService.get(`my-car-technical-diagnose/${technicalDiagnoseId}/${myCarId}`)
  },
  getAll(myCarId, userId) {
    return ApiService.get(`my-car-technical-diagnose/${myCarId}/${userId}/all`)
  },
  add(payload) {
    return ApiService.post(`my-car-technical-diagnose/`, payload)
  },
  edit(technicalDiagnoseId, payload) {
    return ApiService.patch(`my-car-technical-diagnose/${technicalDiagnoseId}`, payload)
  },
  delete(technicalDiagnoseId, myCarId, userId) {
    return ApiService.delete(`my-car-technical-diagnose/${technicalDiagnoseId}/${myCarId}/${userId}`)
  }
}

/* service odpowiedzialny za uwierzytelnianie */
export const AuthService = {
  askQuestion(payload){
    return ApiService.post("question", payload)
  },

  //Klient close account
  closeAccount(payload){
    return ApiService.patch(`close-account/${payload.uid}`, payload)
  },

  // Klient
  login(payload) {
    return ApiService.post("user/login", payload)
  },

  register(payload) {
    return ApiService.post("user", payload)
  },

  updateAccount(userId, dataToSet) {
    return ApiService.patch(`user/${userId}`, dataToSet)
  },

  userData(userId) {
    return ApiService.get(`user/${userId}`)
  },

  // Odnowienie tokenu (ważność 1h) co X minut (zdefiniowane w app.js)
  renewToken() {
    return ApiService.post(`user/renew`)
  },

  // Firma
  loginCompany(payload) {
    return ApiService.post("company/login", payload)
  },

  registerCompany(payload) {
    return ApiService.post("company", payload)
  },

  companyData(companyId) {
    return ApiService.get(`company/${companyId}`)
  },

  renewCompanyToken() {
    return ApiService.post(`company/renew`)
  },

  searchCompany(ctx, payload) {
    return ApiService.post(`company/search`, payload)
  },

  //Reset hasła
  createResetPasswordLink(payload) {
    //console.log("payload", payload)
    return ApiService.post(`${payload.type}/reset-password`, { email: payload.email })
  },
  //Reset hasła z linku
  createResetPasswordFromLink(payload) {
    //console.log(payload)
    return ApiService.patch(`${payload.type}/reset-password`, {
      code: payload.code,
      password: payload.password,
    })
  },
}

export const UserService = {
  getUsers(token) {
    Vue.axios.defaults.headers.Authorization = `Bearer ${token}`
    return ApiService.get("user")
  },
}

/* service odpowiedzialny za zbieranie pobieranie danych dotyczących samochodów */
export const CarDataService = {
  // Marki, modele, rodzaje aut
  getCarBrands() {
    return ApiService.get("car-maker")
  },

  getCarModelsByBrand(carBrandId) {
    return ApiService.get("car-model", carBrandId)
  },

  getCarGenerationsByModel(carModelId) {
    return ApiService.get("car-generation", carModelId)
  },

  getCarSeriesByGen(carGenerationId) {
    return ApiService.get("car-serie", carGenerationId)
  },

  getCarEnginesBySerie(carSerieId) {
    return ApiService.get("car-engine", carSerieId)
  },

  // Car equipment - wyposażenie
  getUpholsteryTypes() {
    return ApiService.get("upholstery-type")
  },

  getUpholsteryColors() {
    return ApiService.get("upholstery-color")
  },

  getPaintTypes() {
    return ApiService.get("paint-type")
  },

  getPaintColors() {
    return ApiService.get("paint-color")
  },

  getRimsTypes() {
    return ApiService.get("car-rims")
  },
}

/* service odpowiedzialny za operacje na kliencie konsumenckim */
export const ClientService = {
  getClientData() {
    return ApiService.get(/* CLIENT ENDPOINT WITH DATA LIKE email, phone number, address etc */)
  },

  updateAddress(userId, address) {
    return ApiService.patch(`user/${userId}`, address)
  },

  updateEmail(userId, email) {
    return ApiService.patch(`user/${userId}`, email)
  },

  updatePhoneNumber(userId, phoneNumber) {
    return ApiService.patch(`user/${userId}`, phoneNumber)
  },

  updateEmailNotification(userId, notification) {
    return ApiService.patch(`user/${userId}`, notification)
  },

  updateSMSNotification(userId, notification) {
    return ApiService.patch(`user/${userId}`, notification)
  },

  changePassword(payload) {
    return ApiService.patch(`user-password/${payload.userId}`, payload)
  },

  /*
        My Car *****************************
    */
  /* Pobranie wszystkich pojazdów zalogowanego użytkownika */
  getCars(userId) {
    return ApiService.get(`my-car/user/${userId}`)
  },

  /* Pobranie konkretnego pojazdu użytkownika */
  getCarById(carId) {
    return ApiService.get(`my-car/${carId}`)
  },

  /* Utworzenie pojazdu */
  createCar(car) {
    return ApiService.post("my-car", car)
  },

  /* Edycja danych pojazdu */
  updateCar(carId, carData) {
    return ApiService.patch(`my-car/${carId}`, carData)
  },

  /* Usunięcie pojazdu */
  deleteCar(carId) {
    return ApiService.delete(`my-car/${carId}`)
  },
  /* ***************************************************** */

  /*
        My Car History *******************************************
    */
  /* Pobranie historii pojazdu */
  getCarHistory(carId) {
    return ApiService.get(`my-car/history/${carId}`)
  },
  /* ********************************************************* */

  /*
    Car Photo
  */
  uploadPhoto(carId, file) {
    return ApiService.upload(`my-car-photo/${carId}`, file)
  },

  deleteCarPhoto(name) {
    //console.log("1", name)
    return ApiService.delete(`my-car-photo/${name}`)
  },
  /* *********************************************************** */

  /* 
        Orders
    */
  /* Pobranie historii zamówień */
  getOrders(userId) {
    return ApiService.get(`order/user/${userId}`)
  },

  getOrderById(orderId) {
    return ApiService.get(`order/${orderId}`)
  },
}

/* service odpowiedzialny za operacje na firmie */
export const CompanyService = {
  updateLogo(slug, file, token) {
    Vue.axios.defaults.headers.Authorization = `Bearer ${token}`
    return ApiService.upload(`company/photo/${slug}`, file)
  },

  updateBankData(id, payload, token) {
    Vue.axios.defaults.headers.Authorization = `Bearer ${token}`
    return ApiService.patch(`company/bank/${id}`, payload)
  },

  getCompanies(token) {
    Vue.axios.defaults.headers.Authorization = `Bearer ${token}`
    return ApiService.get("company")
  },

  getAllCompanyEvents(companyId) {
    return ApiService.get(`event/company/${companyId}`)
  },

  getAllCompanyOrders(companyId) {
    return ApiService.get(`order/company/${companyId}`)
  },

  getEvent(companyId, eventId) {
    return ApiService.get(`event/details-company/${companyId}/${eventId}`)
  },

  createEvent(companyId, eventData) {
    return ApiService.post(`event/${companyId}`, eventData)
  },

  updateEvent(company, event, payload) {
    return ApiService.patch(`event/${company}/${event}`, payload)
  },

  searchServices(token, payload) {
    Vue.axios.defaults.headers.Authorization = `Bearer ${token}`
    return ApiService.post("company/search", payload)
  },

  getOrderById(orderId) {
    return ApiService.get(`order/${orderId}`)
  },

  updateAddress(companySlug, address) {
    return ApiService.patch(`company/${companySlug}`, address)
  },

  updateContact(companySlug, contact) {
    return ApiService.patch(`company/${companySlug}`, contact)
  },

  updateCompanyData(companySlug, companyData) {
    return ApiService.patch(`company/${companySlug}`, companyData)
  },

  changePassword(payload) {
    return ApiService.patch(`company-password/${payload.companyId}`, payload)
  },
}

/* service odpowiedzialny za usługi aplikacji */
export const AppCarsService = {
  getServiceTypes() {
    return ApiService.get("car/services")
  },

  getCompanyServices(id, token) {
    Vue.axios.defaults.headers.Authorization = `Bearer ${token}`
    return ApiService.get(`car/services/${id}`)
  },

  deleteSubtype(id, token, type) {
    Vue.axios.defaults.headers.Authorization = `Bearer ${token}`
    return ApiService.delete(`car/services/${id}/subtype/${type}`)
  },

  deleteService(id, token, service) {
    Vue.axios.defaults.headers.Authorization = `Bearer ${token}`
    return ApiService.delete(`car/services/${id}/service/${service}`)
  },
  updateSubType(id, token, subTypeId, body) {
    Vue.axios.defaults.headers.Authorization = `Bearer ${token}`
    return ApiService.delete(`car/services/${id}/subtype/${subTypeId}`, body)
  },

  updateService(id, token, serviceId, body) {
    Vue.axios.defaults.headers.Authorization = `Bearer ${token}`
    return ApiService.patch(`car/services/${id}/service/${serviceId}`, body)
  },

  addService(id, token, body) {
    //console.log(body)
    Vue.axios.defaults.headers.Authorization = `Bearer ${token}`
    return ApiService.post(`car/services/${id}/service`, body)
  },

  addServices(id, token, body) {
    Vue.axios.defaults.headers.Authorization = `Bearer ${token}`
    return ApiService.post(`car/services/${id}`, body)
  },

  getCompanies() {
    return ApiService.get("company/all")
  },

  getCompaniesByCity(cityName) {
    return ApiService.get(/* Pobranie firm po nazwie miejscowosci */ null, cityName)
  },

  getCompanyDetails(slug) {
    return ApiService.get(`comapny/${slug}`)
  },

  // Zamówienia
  createOrder(orderData) {
    return ApiService.post("order", orderData)
  },

  updateOrderStatus(orderId, companyId, payload) {
    return ApiService.patch(`order/${orderId}/${companyId}`, payload)
  },

  getReservedDates(companySlug, month) {
    return ApiService.get(`company-reserved/${companySlug}/${month}`)
  },
}
