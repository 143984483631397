// Moduł odpowiedzialny za obsługę danych podczas tworzenia zamówienia

import Vue from "vue"
import { AppCarsService } from "../../api/api"

// Zwraca liczbę dni w miesiącu z aktualnego roku
function numberOfDaysInMonth(month) {
  const today = new Date()
  const currentYear = today.getFullYear()

  return new Date(currentYear, month, 0).getDate()
}

// zwraca Array zawierajacy "eventy" wyswietlane w kalendarzu
function createCalendarEvents(month) {
  const hours = ["07", "08", "09", "10", "11", "12", "13", "14", "15", "16"]

  // funkcja zwraca Array zawierajacy obiekty o strukturze odpowiedniej dla komponentu kalendarza (vuecal):
  /* 
    start: Date,
    end: Date,
    title: String,
    available: bool
  */
  const createEvents = (day, arePastEvents) => {
    const createdEvents = hours.map(hour => {
      const contextDay = new Date(day.getFullYear(), day.getMonth(), day.getDate(), hour, 0)

      const startDate = contextDay
      const endDate = startDate.addHours(1)
      const isAvailable = !arePastEvents

      return {
        start: startDate,
        end: endDate,
        title: hour,
        available: isAvailable,
      }
    })

    return createdEvents
  }

  const events = []
  const today = new Date()
  const firstDay = new Date(today.getFullYear(), month, 1)
  const lastDay = new Date(today.getFullYear(), month, numberOfDaysInMonth(month))
  let currentDay = firstDay

  while (currentDay < today) {
    const eventsToInsert = createEvents(currentDay, true)
    currentDay = currentDay.addDays(1)
    events.push(eventsToInsert)
  }

  while (currentDay < lastDay) {
    const eventsToInsert = createEvents(currentDay, false)
    currentDay = currentDay.addDays(1)
    events.push(eventsToInsert)
  }

  return events.flat()
}

function filterReservedDates(eventsArr, reservedDates) {
  const datesToDisable = reservedDates.map(dateStr => {
    const splitted = dateStr.split(" ")
    const day = splitted[0]
    const time = splitted[1]
    const daySplitted = day.split("-")
    const timeSplitted = time.split(":")
    const month = Number(daySplitted[1]) - 1

    const d = new Date(daySplitted[0], month, daySplitted[2], timeSplitted[0], timeSplitted[1])
    return d
  })

  const finalCalendarEvents = eventsArr.map(calendarEvent => {
    for (let index = 0; index < datesToDisable.length; index++) {
      if (!calendarEvent.available) {
        return calendarEvent
      }
      const dateToDisable = datesToDisable[index]

      if (
        dateToDisable.getFullYear() === calendarEvent.start.getFullYear() &&
        dateToDisable.getMonth() === calendarEvent.start.getMonth() &&
        dateToDisable.getDate() === calendarEvent.start.getDate() &&
        dateToDisable.getHours() === calendarEvent.start.getHours()
      ) {
        calendarEvent.available = false
        return calendarEvent
      }
    }

    return calendarEvent
  })

  return finalCalendarEvents
}

const state = {
  contextCompany: null,
  selectableDates: new Map(),

  order: {
    selectedDate: null,
    car: {},
    services: {},
    contact: {},
  },
}

const getters = {
  pickPayment(state) {
    return state.order_payment_type_id
  },

  getSelectableDates(state) {
    return state.selectableDates
  },

  selectableDate: state => key => {
    if (state.selectableDates === null || state.selectableDates === undefined) {
      return false
    }

    if (state.selectableDates.size === 0) {
      return false
    }

    if (state.selectableDates.has(key)) {
      return state.selectableDates.get(key)
    }

    return false
  },

  order(state) {
    return state.order
  },

  contextCompany(state) {
    return state.contextCompany
  },

  selectedServices(state) {
    let serv = {}

    for (let category in state.order.services) {
      const servicesInCategory = state.order.services[category].filter(service => {
        return service.checked && service.variant !== null
      })

      serv[category] = servicesInCategory
    }

    return serv
  },

  orderValuation(state) {
    let valuation = 0
    for (let category in state.order.services) {
      const categoryVal = state.order.services[category].reduce((val, service) => {
        if (service.variant !== null) {
          return val + Number(service.variant.value)
        }

        return val
      }, 0)

      valuation += categoryVal
    }

    const totalPrice = Number(valuation).toFixed(2)

    return totalPrice
  },
}

const mutations = {
  initDatesMap(state, availableDates) {
    const avDatesPairs = availableDates.map(avDate => {
      return [avDate.start, false]
    })

    state.selectableDates = new Map(avDatesPairs)
  },

  setContextCompany(state, company) {
    state.contextCompany = company
  },

  selectDate(state, payload) {
    const dateKey = payload.title
    const value = payload.value

    for (const key of state.selectableDates.keys()) {
      state.selectableDates.set(key, false)
    }

    state.selectableDates.set(dateKey, value)

    const entriesArr = Array.from(state.selectableDates)

    state.selectableDates = new Map(entriesArr)
    state.order.selectedDate = dateKey
  },

  setCar(state, car) {
    state.order.car = car
  },

  setPaymentType(state, value) {
    state.order_payment_type_id = value
  },

  setOrderServices(state, payload) {
    const category = payload.category
    const services = payload.services

    Vue.set(state.order.services, category, services)
  },
}

const actions = {
  async getContextCompanyBySlug(context, slug) {
    try {
      const company = context.rootGetters["searchService/getCompanyBySlug"](slug)
      context.commit("setContextCompany", company)
      const res = await context.dispatch("getContextCompanyAvailableDates")
      return res
    } catch (err) {
      return err
    }
  },

  async getContextCompanyAvailableDates(context) {
    try {
      const companySlug = context.state.contextCompany.slug
      const currentMonth = new Date().getMonth()
      const companyEventsRes = await AppCarsService.getReservedDates(companySlug, currentMonth + 1)

      const calendarEvents = createCalendarEvents(currentMonth)
      const statefullCalendarEvents = filterReservedDates(calendarEvents, companyEventsRes.data.content)

      context.commit("companyData/setAvailableDates", statefullCalendarEvents, { root: true })
      context.commit("initDatesMap", statefullCalendarEvents)

      return companyEventsRes
    } catch (err) {
      return err.response || err
    }
  },

  async initDatesMap(context) {
    const avDates = context.rootGetters["companyData/getAvailableDates"]

    context.commit("initDatesMap", avDates)
  },

  async pickPayment(context, paymentId) {
    context.commit("setPaymentType", paymentId)
  },

  async pickCarForOrder(context, carId) {
    const car = context.rootGetters["clientCars/getCarById"](carId)

    context.commit("setCar", car)
  },

  async createOrder(context) {
    try {
      const email = context.rootGetters["clientProfile/email"] || ''
      const phone = context.rootGetters["clientProfile/phone"] || ''
      const userId = context.rootGetters["auth/id"] || ''
      const companySlug = context.state.contextCompany.slug || ''
      const myCarId = context.state.order.car.id || ''
      const orderPaymentType = context.rootGetters["appcarsService/pickPayment"] || ''
      const summaryValue = context.getters["orderValuation"] || ''

      // Odpowiedni format daty
      const selectedD = context.state.order.selectedDate
      let hoursStr = selectedD.getHours()

      if (hoursStr < 10) {
        hoursStr = `0${hoursStr}`
      }

      let minutesStr = selectedD.getMinutes()

      if (minutesStr < 10) {
        minutesStr = `0${minutesStr}`
      }
      const bookingTime = `${selectedD.getFullYear()}-${selectedD.getMonth() + 1}-${selectedD.getDate()} ${hoursStr}:${minutesStr}`

      // Wyliczenie ilości roboczodni -> znalezienie max parametru working_days w service variant
      const maxWorkingDaysGroupedByCategory = []

      for (const category in context.state.order.services) {
        const selectedServicesInCategory = context.state.order.services[category].filter(service => {
          return service.checked && service.variant
        })

        const servicesWorkingDays = selectedServicesInCategory.map(service => {
          return service.variant.working_days
        })

        const maxWorkingDaysInCategory = Math.max.apply(Math, servicesWorkingDays)
        maxWorkingDaysGroupedByCategory.push(maxWorkingDaysInCategory)
      }

      const summaryWorkingDays = Math.max.apply(Math, maxWorkingDaysGroupedByCategory)

      // Utworzenie tablicy z id services
      const servicesIds = []

      for (const category in context.state.order.services) {
        const selectedServicesInCategory = context.state.order.services[category].filter(service => {
          return service.checked && service.variant
        })

        const servicesIdInCategory = selectedServicesInCategory.map(service => {
          return service.variant.id
        })

        Array.prototype.push.apply(servicesIds, servicesIdInCategory)
      }
      let hr = bookingTime.split(" ")[1]
      let dt = bookingTime.split(" ")[0].split("-")
      const orderData = {
        email: email,
        phone: phone,
        user_id: userId,
        company: companySlug,
        booking_time: `${dt[0]}-${dt[1].length == 1 ? '0'+dt[1] : dt[1]}-${dt[2].length == 1 ? '0'+dt[2] : dt[2]} ${hr}`,
        summary_value: summaryValue,
        summary_working_days: summaryWorkingDays,
        my_car_id: myCarId,
        order_payment_type_id: orderPaymentType,
        services: servicesIds,
      }
      const createOrderRes = await AppCarsService.createOrder(orderData)

      return createOrderRes
    } catch (err) {
      return err.response || err
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
