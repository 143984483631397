//import Vue from "vue"

import { AppCarsService } from "../../api/api"

const state = {
  types: [],
  services: [],
  selectedService: {},
  selectedSubservice: {},
}

const getters = {}

const mutations = {
  setTypes(state, types) {
    state.types = types
  },
  setServices(state, services) {
    state.services = services
  },
  setSelectedService(state, selectedService) {
    state.selectedService = selectedService
  },
  setSelectedSubservice(state, selectedSubservice) {
    state.selectedSubservice = selectedSubservice
  },
}

const actions = {
  async servicesType(context) {
    try {
      const response = await AppCarsService.getServiceTypes()
      context.commit("setTypes", response.data.content)
    } catch (error) {
      return error
    }
  },

  async companyServices(context, payload) {
    const token = payload.token
    const companyId = payload.companyId
    try {
      const response = await AppCarsService.getCompanyServices(companyId, token)
      context.commit("setServices", response.data.content)
    } catch (error) {
      return error
    }
  },

  async deleteSubtype(context, payload) {
    const token = payload.token
    const companyId = payload.companyId
    const subtype = payload.subtypeId
    try {
      const response = await AppCarsService.deleteSubtype(companyId, token, subtype)
      if (response.data.success) {
        const services = context.state.services.filter(ss => ss.id != subtype)
        context.commit("setServices", services)
        return true
      }
      return false
    } catch (error) {
      return false
    }
  },
  async deleteService(context, payload) {
    const token = payload.token
    const companyId = payload.companyId
    const service = payload.serviceId

    try {
      const response = await AppCarsService.deleteService(companyId, token, service)
      if (response.data.success) {
        const services = context.state.services.map(ss =>
          ss.filter(service => {
            return service.id != service
          })
        )
        context.commit("setServices", services)
        return true
      }
      return false
    } catch (error) {
      return false
    }
  },

  async addService(context, payload) {
    const token = payload.token
    const companyId = payload.companyId
    const body = payload.body
    try {
      const response = await AppCarsService.addService(companyId, token, body)
      return response
    } catch (error) {
      return error
    }
  },

  async editService(context, payload) {
    const token = payload.token
    const companyId = payload.companyId
    const serviceId = payload.serviceId
    const body = payload.body

    try {
      const response = await AppCarsService.updateService(companyId, token, serviceId, body)
      
      return response

    } catch (error) {
      return error
    }
  },

  async addServices(context, payload) {
    const body = {
      car_service_type_id: payload.type_id,
      car_service_subtypes: payload.services,
    }
    const token = payload.token
    const id = payload.id
    try {
      const response = await AppCarsService.addServices(id, token, body)
      if (response.data.success) {
        context.dispatch("companyServices", { token: token, companyId: id })
        return true
      } else return false
    } catch (error) {
      return false
    }
  },

  //TODO update subtype service

  async updateService(context, payload) {
    const token = payload.token
    const companyId = payload.companyId
    const serviceId = payload.serviceId
    const service = payload.body
    try {
      const response = await AppCarsService.updateService(companyId, token, serviceId, service)
      if (response.data.success) {
        const services = context.state.services.map(subservice => {
          subservice.services.map(s => {
            if (s.id == serviceId) {
              s = { ...s, ...service }
            }
          })
        })
        context.commit("setServices", services)
        return true
      }
      return false
    } catch (error) {
      return error
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
