/* 
Moduł odpowiedzialny za pobieranie, tworzenie i edycje zamowien zalogowanej firmy
*/
import { CompanyService, AppCarsService } from "@/api/api"
import ApiService from "../../api/api"

const state = {
  companyEvents: [],
  companyOrders: [],
  contextOrder: {},
  orderStatusList: [
    { id: 1, name: "Zarejestrowano" },
    { id: 2, name: "W realizacji" },
    { id: 3, name: "Uwagi" },
    { id: 4, name: "Zrealizowano" },
    { id: 5, name: "Problem" },
    { id: 6, name: "Zamówienie opłacone" },
  ],
}

const getters = {
  getCompanyEvents(state) {
    return state.companyEvents.map(event => {
      return { ...event, content: event.description }
    })
  },



  getCompanyEventById: state => eventId => {
    return state.companyEvents.find(companyEvent => companyEvent.id === eventId)
  },

  getOrders(state) {
    return state.companyOrders
  },

  getContextOrder(state) {
    return state.contextOrder
  },

  getOrderStatusById: state => statusId => {
    return state.orderStatusList.find(status => status.id === statusId)
  },
}

const mutations = {

  setOrders(state, orders) {
    state.companyOrders = orders
  },

  setCompanyEvents(state, events) {
    /* 
      W bazie daty są przechowywane w postaci tekstu o wzorcu: YYYY-MM-DD HH-MM
      Daty te trzeba przekonwertować na obiekty typu Date
    */
    const properEvents = events.map(companyEvent => {
      const dateToSet = new Date(companyEvent.date)
      companyEvent.start = dateToSet
      companyEvent.end = dateToSet.addHours(1)
      delete companyEvent.date
      return companyEvent
    })

    state.companyEvents = properEvents
  },

  pushCompanyEvent(state, eventToAdd) {
    const dateToSet = new Date(eventToAdd.date)
    eventToAdd.start = dateToSet
    eventToAdd.end = dateToSet.addHours(1)

    delete eventToAdd.date

    state.companyEvents.push(eventToAdd)
  },

  setContextOrder(state, order) {
    state.contextOrder = order
  },

  setCompanyOrders(state, orders){
    state.companyOrders = orders
  }
}

const actions = {
  async getOrderById(context, orderId) {
    try {
      const orderRes = await CompanyService.getOrderById(orderId)
      context.commit("setContextOrder", orderRes.data.content)
      return orderRes
    } catch (err) {
      return err.response || err
    }
  },

  async getOrdersPage(context, url) {
    try {url
      /* 
        Specjalnie dla tej funkcji zaimplementowano funkcję ApiService.getWithSlashEscape.
        Spowodowane jest to tym, że ApiService.get dodaje na końcu URL znak "/", przez co endpoint nie zwracał prawidłowych danych.
        Zmiana implementacji funkcji ApiService.get mogłaby spowodować niedziałanie innych zapytań.
      */
        const companyUid = context.rootGetters["companyProfile/uid"]
       // const eventsRes = await CompanyService.getAllCompanyEvents(companyUid)
      //console.log('aaa')
       const ordersPageRes = await ApiService.get('company', companyUid)
      //console.log(ordersPageRes)
      context.commit("setCompanyOrders", ordersPageRes.data.content)

      return ordersPageRes
    } catch (error) {
      return error.response || error
    }
  },

  async getAllEvents(context) {
    try {
      const companyUid = context.rootGetters["companyProfile/uid"]
      const eventsRes = await CompanyService.getAllCompanyEvents(companyUid)

      if (eventsRes.status !== 200) {
        throw new Error("Nie udało się pobrać wydarzeń")
      }
      context.commit("setCompanyEvents", eventsRes.data.content)
      eventsRes.data.content.map(event => (event.description != "" ? (event.content = event.description) : (event.content = "")))

      return eventsRes
    } catch (error) {
      return error.response
    }
  },

  async createEvent(context, eventData) {
    try {
      const createEventRes = await CompanyService.createEvent(eventData.company_id, eventData)

      if (createEventRes.status !== 201) {
        throw new Error("Nie udało się utworzyć wydarzenia")
      }

      return createEventRes
    } catch (error) {
      return error.response
    }
  },

  async editEvent(context, payload) {
    try {
      //console.log(payload.data)
      let d = payload.data.date.toISOString().split("T")
      payload.data.date = `${d[0]} ${d[1].split(".")[0]}`
      const response = await CompanyService.updateEvent(payload.company, payload.event, payload.data)
      return response
    } catch (error) {
      console.error(error)
      return error
    }
  },

  async updateOrderStatus(context, payload) {
    try {
      const orderId = payload.orderId
      const companyId = payload.companyId
      const content = {
        order_status: payload.order_status,
        comment: payload.comment,
      }
      const updateStatusRes = await AppCarsService.updateOrderStatus(orderId, companyId, content)

      if (updateStatusRes.status === 201) {
        await context.dispatch("getOrderById", orderId)
      }

      return updateStatusRes
    } catch (err) {
      return err.response || err
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
