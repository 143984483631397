import { CarDataService } from '@/api/api'

const state = {
    carBrands: [],
    carModels: [],
    carGenerations: [],
    carSeries: [],
    carEngines: [],

    // equipment
    upholsteryTypes: [],
    upholsteryColors: [],
    paintTypes: [],
    paintColors: [],
    rimsTypes: []
}

const getters = {
    carBrands(state) {
        return state.carBrands
    },

    carModels(state) {
        return state.carModels
    },

    carGenerations(state) {
        return state.carGenerations
    },

    carSeries(state) {
        return state.carSeries
    },

    carEngines(state) {
        return state.carEngines
    },

    upholsteryTypes(state) {
        return state.upholsteryTypes
    },
    
    upholsteryColors(state) {
        return state.upholsteryColors
    },

    paintTypes(state) {
        return state.paintTypes
    },

    paintColors(state) {
        return state.paintColors
    },

    rimsTypes(state) {
        return state.rimsTypes
    }
}

const mutations = {
    setCarBrands(state, brands) {
        state.carBrands = brands
    },

    setCarModels(state, models) {
        state.carModels = models
    },

    setCarGenerations(state, gens) {
        state.carGenerations = gens
    },

    setCarSeries(state, series) {
        state.carSeries = series
    },

    setCarEngines(state, engines) {
        state.carEngines = engines
    },

    setUpholsteryTypes(state, types) {
        state.upholsteryTypes = types
    },

    setUpholsteryColors(state, colors) {
        state.upholsteryColors = colors
    },

    setPaintTypes(state, types) {
        state.paintTypes = types
    },

    setPaintColors(state, colors) {
        state.paintColors = colors
    },

    setRimsTypes(state, types) {
        state.rimsTypes = types
    }
}

const actions = {
    async getCarData(context) {
        try {
            const carBrandsRes = await CarDataService.getCarBrands()
            const upholsteryTypesRes = await CarDataService.getUpholsteryTypes()
            const upholsteryColorsRes = await CarDataService.getUpholsteryColors()
            const paintTypesRes = await CarDataService.getPaintTypes()
            const paintColorsRes = await CarDataService.getPaintColors()
            const rimsTypesRes = await CarDataService.getRimsTypes()

            context.commit("setCarBrands", carBrandsRes.data.content)
            context.commit("setUpholsteryTypes", upholsteryTypesRes.data.content)
            context.commit("setUpholsteryColors", upholsteryColorsRes.data.content)
            context.commit("setPaintTypes", paintTypesRes.data.content)
            context.commit("setPaintColors", paintColorsRes.data.content)
            context.commit("setRimsTypes", rimsTypesRes.data.content)
            return [carBrandsRes, upholsteryTypesRes, upholsteryColorsRes, paintTypesRes, paintColorsRes, rimsTypesRes]    
        } catch (error) {
            return error.response
        }
          
    },

    async getCarModelsByBrandId(context, carBrandId) {
        try {
            const carModelsRes = await CarDataService.getCarModelsByBrand(carBrandId)
            context.commit("setCarModels", carModelsRes.data.content)
            return carModelsRes
        }
        catch(err) {
            return err.response
        }
    },

    async getCarGenerationsByModel(context, carModelId) {
        try {
            const carGens = await CarDataService.getCarGenerationsByModel(carModelId)
            context.commit("setCarGenerations", carGens.data.content)

            return carGens
        }
        catch(err) {
            return err.response
        }
    },

    async getCarSeriesByGen(context, carGenId) {
        try {
            const carSeries = await CarDataService.getCarSeriesByGen(carGenId)
            context.commit("setCarSeries", carSeries.data.content)

            return carSeries
        }
        catch(err) {
            return err.response
        }
    },

    async getCarEnginesBySerie(context, carSerieId) {
        try {
            const carEngines = await CarDataService.getCarEnginesBySerie(carSerieId)
            context.commit("setCarEngines", carEngines.data.content)

            return carEngines
        }
        catch(err) {
            return err.response
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}