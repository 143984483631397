//import Vue from "vue"

import { InsurenceApi } from "../../api/api"

const state = {
  insurences: [],
  insurence: {},
}

const getters = {
  getInsurences: state  => {
    return state.insurences
  },
  getInsurence: state => {
      return state.insurence
  }
}

const mutations = {
  setInsurences(state, insurences) {
    state.insurences = insurences
  },
  setInsurence(state, insurence) {
    state.insurence = insurence
},
pushInsurence(state, insurence) {
    state.insurences = [...state.insurences, insurence]
},
modifyInsurence(state, insurence) {
    let index = state.insurences.findIndex(i => i.id == insurence.id)
    state.insurences[index] = insurence
},
deleteInsurence(state, id) {
    state.insurences = state.insurences.filter(insurence => insurence.id != id)
},
}

const actions = {
  async getInsurence(context, payload) {
    try {
      let insurenceId = payload.insurenceId
      let myCarId = payload.myCarId
      const response = await InsurenceApi.getOne(insurenceId, myCarId)
      context.commit("setInsurence", response.data.content.insurence)
      return true
    } catch (error) {
        context.commit("setInsurence", null)
      return error
    }
  },

  async getAll(context, payload) {
    try {
      let myCarId = payload.myCarId
      let userId = payload.userId
      const response = await InsurenceApi.getAll(myCarId, userId)
      context.commit("setInsurences", response.data.content.insurences)
      return true
    } catch (error) {
        context.commit("setInsurences", [])
      return error
    }
  },

  async add(context, payload) {
    try {
      const response = await InsurenceApi.add(payload)
      context.commit("pushInsurence", response.data.content.insurence)
      return true
    } catch (error) {
       // context.commit("setInsurences", [])
      return error
    }
  },

  async edit(context, payload) {
    try {
      const response = await InsurenceApi.edit(payload.id, payload)
      context.commit("setInsurences", response.data.insurences)
      return true
    } catch (error) {
     //   context.commit("setInsurences", [])
      return error
    }
  },

  async delete(context, payload) {
    try {
      const response = await InsurenceApi.delete(payload.id, payload.my_car_id, payload.user_id)
      context.commit("setInsurences", response.data.insurences)
      return true
    } catch (error) {
      //  context.commit("setInsurences", [])
      return error
    }
  },

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
