//import Vue from "vue"

import { AppCarsService, CompanyService } from "../../api/api"

const state = {
  companies: [],
  types: [],
}

const getters = {
  getCompanyBySlug: state => slug => {
    return state.companies.find(company => company.slug === slug)
  },
}

const mutations = {
  setCompanies(state, companies) {
    //console.log('1', companies)
    state.companies = companies
  },
  setTypes(state, types) {
    state.types = types
  },
}

const actions = {
  async searchServices(context, payload) {
    try {
      let token = context.rootState.auth.token
      const response = await CompanyService.searchServices(token, payload)
      context.commit("setCompanies", response.data.content)
    } catch (error) {
      return error
    }
  },

  async servicesType(context) {
    try {
      const response = await AppCarsService.getServiceTypes()
      context.commit("setTypes", response.data.content)
    } catch (error) {
      return error
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
