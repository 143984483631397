import { ClientService } from "@/api/api"

const state = {
  cars: [],
  carDetails: [],
  carHistories: [],
}

const getters = {
  cars(state) {
    return state.cars
  },

  getCarById: state => carId => {
    return state.cars.find(car => car.id == carId)
  },

  getDetails: state => {
    return state.carDetails
  },

  getCarHistory: state => carId => {
    return state.carHistories.filter(history => {
      return history.carId == carId
    })
  },
}

const mutations = {
  setCars(state, cars) {
    state.cars = cars
  },

  setCar(state, payload) {
    const data = payload.data
    state.carDetails = data
  },

  setCarHistory(state, payload) {
    const carId = payload.carId
    const history = payload.history

    const index = state.carHistories.findIndex(history => history.carId === carId)

    if (index > -1) {
      state.carHistories.splice(index, 1, {
        carId: carId,
        history: history,
      })
    } else {
      state.carHistories.push({
        carId: carId,
        history: history,
      })
    }
  },
}

const actions = {
  async getCars(context) {
    try {
      const userId = context.rootGetters["auth/id"]
      const carsRes = await ClientService.getCars(userId)

      await context.commit("setCars", carsRes.data.content)

      return carsRes
    } catch (error) {
      return error.response
    }
  },

  async createCar(context, carCreatorForm) {
    try {
      carCreatorForm.user_id = context.rootGetters["auth/id"]
      const carsRes = await ClientService.createCar(carCreatorForm)

      return carsRes
    } catch (error) {
      return error.response
    }
  },

  async updateCar(context, carUpdateForm) {
    try {
      const carsRes = await ClientService.updateCar(carUpdateForm)
      return carsRes
    } catch (error) {
      return error
    }
  },

  async getCarDetails(context, carId) {
    try {
      const data = await ClientService.getCarById(carId)
      context.commit("setCar", { carId: carId, data: data.data.content })
      return data
    } catch (error) {
      return error.response
    }
  },

  async getCarHistory(context, carId) {
    try {
      const history = await ClientService.getCarHistory(carId)
      if (history.status === 200) {
        context.commit("setCarHistory", { carId: carId, history: history.data.content })
      }
      return history
    } catch (error) {
      return error.response || error
    }
  },

  async uploadCarPhoto(context, payload) {
    try {
      const response = await ClientService.uploadPhoto(payload.carId, payload.file)
      return response
    } catch (error) {
      return error.response
    }
  },

  async deleteCarPhoto(context, name) {
    try {
      const result = await ClientService.deleteCarPhoto(name)
      return result
    } catch (error) {
      return error.response
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
