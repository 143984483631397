import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: () => import("@/views/Home"),
    },
    {
      path: "/auth",
      name: "MainAuth",
      component: () => import("@/views/MainAuth"),
    },
    {
      path: "/client-login",
      name: "ClientLogin",
      component: () => import("@/views/ClientLogin"),
    },
    {
      path: "/client-registration",
      name: "ClientRegistration",
      component: () => import("@/views/ClientRegistration"),
    },
    {
      path: "/company-login",
      name: "CompanyLogin",
      component: () => import("@/views/CompanyLogin"),
    },
    {
      path: "/company-registration",
      name: "CompanyRegistration",
      component: () => import("@/views/CompanyRegistration"),
    },
    {
      path: "/restore-password/:type",
      name: "RestorePassword",
      component: () => import("@/views/RestorePassword"),
    },
    {
      path: "/new-password/:code/:type",
      name: "SetUpPassword",
      component: () => import("@/views/SetUpPassword"),
    },
    {
      path: "/explore",
      component: () => import("@/views/client/ServiceExplorator"),
      children: [
        {
          path: "",
          name: "ExploratorStartSearch",
          component: () => import("@/views/client/ExploratorStartSearch"),
        },
        {
          path: ":cityName",
          name: "CompaniesListing",
          component: () => import("@/views/client/CompaniesListing"),
        },
        {
          path: "company/:id",
          name: "Company",
          component: () => import("@/views/client/Company"),
        },
        {
          path: "company/:id/order",
          name: "CreateOrder",
          component: () => import("@/views/client/CreateOrder"),
        },
      ],
    },
    {
      path: "/insurences",
      name: "Insurences",
      component: () => import("@/views/client/Insurences")
    },
    {
      path: "/technical-diagnostic",
      name: "TechnicalDiagnostic",
      component: () => import("@/views/client/TechnicalDiagnostic")
    },
    {
      path: "/stats",
      name: "Stats",
      component: () => import("@/views/client/Stats")
    },

    {
      path: "/profile",
      name: "Profile",
      component: () => import("@/views/client/Profile"),
    },
    {
      path: "/cars",
      name: "Cars",
      component: () => import("@/views/client/Cars"),
      children: [
        /* {
          path: "all",
          name: "Cars",
          component: () => import("@/views/client/CarsListing"),
        }, */
        {
          path: ":id",
          name: "Car",
          component: () => import("@/views/client/Car"),
          children: [
            {
              path: ":myCarId/:carHistoryId",
              name: "CarHistory",
              component: () => import("@/views/client/CarHistory"),
            },
          ],
        },
        {
          path: "new",
          name: "NewCar",
          component: () => import("@/views/client/NewCar"),
        },
      ],
    },
    {
      path: "/orders",
      component: () => import("@/views/client/Orders"),
      children: [
        {
          path: "all",
          name: "Orders",
          component: () => import("@/views/client/OrdersListing"),
        },
        {
          path: ":id",
          name: "Order",
          component: () => import("@/views/client/Order"),
        },
      ],
    },
    {
      path: "/company",
      name: "ManageCompany",
      component: () => import("@/views/company/Manage"),
      children: [
        {
          path: "profile",
          name: "CompanyProfile",
          component: () => import("@/views/company/Profile"),
        },
        {
          path: "orders",
          name: "CompanyOrders",
          component: () => import("@/views/company/Orders"),
        },
        {
          path: "orders-list",
          name: "CompanyOrdersList",
          component: () => import("@/views/company/OrdersListing"),
        },
        {
          path: "services",
          name: "CompanyServices",
          component: () => import("@/views/company/Services"),
        },
        {
          path: "orders/:id",
          name: "OrderDetails",
          component: () => import("@/views/company/OrderDetails"),
        },
      ],
    },
    {
      path: "/admin-login",
      name: "AdminLogin",
      component: () => import("@/views/admin/AdminLogin"),
    },
    {
      path: "/admin-panel",
      name: "AdminPanel",
      component: () => import("@/views/admin/AdminPanel"),
      children: [
        {
          path: "admin-account",
          name: "AdminAccount",
          component: () => import("@/views/admin/AdminAccount"),
        },
        {
          path: "manage-users",
          component: () => import("@/views/admin/manage/AdminUsers"),
        },
        {
          path: "manage-companies",
          component: () => import("@/views/admin/manage/AdminCompanies"),
        },
        {
          path: "manage-cars",
          component: () => import("@/views/admin/manage/AdminCars"),
        },
        {
          path: "manage-events",
          component: () => import("@/views/admin/manage/AdminEvents"),
        },
        {
          path: "manage-orders",
          component: () => import("@/views/admin/manage/AdminOrders"),
        },
        {
          path: "manage-paints",
          component: () => import("@/views/admin/manage/AdminPaints"),
        },
        {
          path: "manage-rims",
          component: () => import("@/views/admin/manage/AdminRims"),
        },
        {
          path: "manage-upholsteries",
          component: () => import("@/views/admin/manage/AdminUpholsteries"),
        },
      ],
    },
    {
      path: "*",
      name: "Home",
      component: () => import("@/views/Home"),
    },
  ],
})

export default router
