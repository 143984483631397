import { AuthService } from "@/api/api"
import Vue from "vue"
import Cookies from "js-cookie"
import { authHeader } from "../../api/api"

const state = {
  user: {},
  id: "",
  token: "",
  type: "",
  isAuthenticated: false,
}

const getters = {
  id(state) {
    return state.id
  },

  currentUser(state) {
    return state.user
  },

  authToken(state) {
    return state.token
  },

  currentUserId(state) {
    return state.id
  },

  account(state) {
    return state.type
  },

  isAuthenticated(state) {
    return state.isAuthenticated
  },
}

const mutations = {
  setAuth(state, payload) {
    state.id = payload.id
    state.token = payload.token
    state.type = payload.type
    state.isAuthenticated = true
    Cookies.set("token", payload.token, { expires: 1 / 24 })
    authHeader(payload.token)
  },

  setToken(state, newToken) {
    Vue.axios.defaults.headers.common["Authorization"] = "Bearer " + newToken
    state.token = newToken
    Cookies.set("token", newToken, { expires: 1 / 24 })
  },

  setUser(state, payload) {
    state.user = payload
  },

  logout(state) {
    state.user = {}
    state.id = ""
    state.token = ""
    state.type = ""
    state.isAuthenticated = false
  },

  setBankData(state, payload){
    state.user.details.bank_name = payload.bank_name
    state.user.details.bank_number = payload.bank_number
  }
}

const actions = {
  async closeAccount(context, accountData) {
    try{
      const response = await AuthService.closeAccount(accountData)
      if(response.status == 201) return true
      return false
    }catch(error){
      console.error(error)
      return false
    }
  },

  async login(context, loginData) {
    try {
      const response = await AuthService.login(loginData)
      context.commit("setAuth", response.data.content)
      return response
    } catch (error) {
      console.error(`Bład logowania: ${error}`)
    }
  },

  async register(context, registrationData) {
    try {
      const response = await AuthService.register(registrationData)
      return response
    } catch (error) {
      console.error(`Błąd rejestracji: ${error}`)
      return error.response
    }
  },

  async loadUserData(context, userId) {
    try {
      const response = await AuthService.userData(userId)

      if (!response.data.success) {
        throw "Nie udało się pobrać danych użytkownika"
      }

      context.commit("setUser", response.data.content)
      context.commit("clientProfile/setProfile", response.data.content, { root: true })
    } catch (error) {
      console.error(`Bład pobierania danych: ${error}`)
    }
  },

  async logout(context) {
    context.commit("logout")
    return true
  },

  async renewToken(context) {
    try {
      const response = await AuthService.renewToken()
      context.commit("setToken", response.data.content.token)
      return response
    } catch (error) {
      console.error(`Błąd w czasie odnawiania tokenu: ${error}`)
    }
  },

  async registerCompany(context, registrationData) {
    try {
      const response = await AuthService.registerCompany(registrationData)
      return response
    } catch (error) {
      console.error(`Błąd rejestracji: ${error}`)
    }
  },

  async loginCompany(context, loginData) {
    try {
      const response = await AuthService.loginCompany(loginData)
      context.commit("setAuth", response.data.content)
      if (!response.data.success) {
        throw "Nie udało się zalogować"
      }

      const loadCompanyDataRes = await context.dispatch("loadCompanyData", response.data.content.id)

      return [response, loadCompanyDataRes]
    } catch (error) {
      console.error(`Bład logowania: ${error}`)
      return error.response
    }
  },

  async renewCompanyToken(context) {
    try {
      const response = await AuthService.renewCompanyToken()
      context.commit("setToken", response.data.content.token)
      return response
    } catch (error) {
      console.error(`Błąd w czasie odnawiania tokenu: ${error}`)
    }
  },

  async loadCompanyData(context, companyId) {
    try {
      const response = await AuthService.companyData(companyId)

      if (response.status !== 200) {
        throw new Error("Nie udało się pobrać danych firmy")
      }

      context.commit("setUser", response.data.content)
      context.commit("companyProfile/setProfile", response.data.content, { root: true })
      return response
    } catch (error) {
      console.error(`Bład pobierania danych: ${error}`)
      return error.response
    }
  },

  async resetPasswordLink(context, payload) {
    try {
      const response = await AuthService.createResetPasswordLink(payload)
      return response.status
    } catch (error) {
      return error.response.status
    }
  },

  async resetPasswordFromLink(context, payload) {
    try {
      const response = await AuthService.createResetPasswordFromLink(payload)
      return response.status
    } catch (error) {
      return 400
    }
  },

  async question(context, payload) {
    try{
      const response = await AuthService.askQuestion(payload)
      return response.status
    }catch(error){
      return 400
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
