//import Vue from "vue"

import { TechnicalDiagnoseApi } from "../../api/api"

const state = {
  technical_diagnoses: [],
  technical_diagnose: {},
}

const getters = {
  getTechnicalDiagnoses: state  => {
    return state.technical_diagnoses
  },
  getTechnicalDiagnose: state => {
      return state.technical_diagnose
  }
}

const mutations = {
  setTechnicalDiagnoses(state, technical_diagnoses) {
    state.technical_diagnoses = technical_diagnoses
  },
  setTechnicalDiagnose(state, technical_diagnose) {
    state.technical_diagnose = technical_diagnose
},
pushTechnicalDiagnose(state, technical_diagnose) {
    state.technical_diagnoses = [...state.technical_diagnoses, technical_diagnose]
},
modifyTechnicalDiagnose(state, technical_diagnose) {
    let index = state.technical_diagnoses.findIndex(i => i.id == technical_diagnose.id)
    state.technical_diagnoses[index] = technical_diagnose
},
deleteTechnicalDiagnose(state, id) {
    state.technical_diagnoses = state.technical_diagnoses.filter(technical_diagnose => technical_diagnose.id != id)
},
}

const actions = {
  async getTechnicalDiagnose(context, payload) {
    try {
      let technical_diagnose_id = payload.technical_diagnose_id
      let myCarId = payload.myCarId
      const response = await TechnicalDiagnoseApi.getOne(technical_diagnose_id, myCarId)
      context.commit("setTechnicalDiagnose", response.data.content.technical_diagnose)
      return true
    } catch (error) {
        context.commit("setTechnicalDiagnose", null)
      return error
    }
  },

  async getAll(context, payload) {
    try {
      let myCarId = payload.myCarId
      let userId = payload.userId
      const response = await TechnicalDiagnoseApi.getAll(myCarId, userId)
      context.commit("setTechnicalDiagnoses", response.data.content.technical_diagnoses)
      return true
    } catch (error) {
        context.commit("setTechnicalDiagnoses", [])
      return error
    }
  },

  async add(context, payload) {
    try {
      const response = await TechnicalDiagnoseApi.add(payload)
      context.commit("pushTechnicalDiagnose", response.data.content.technical_diagnose)
      return true
    } catch (error) {
       // context.commit("settechnical_diagnoses", [])
      return error
    }
  },

  async edit(context, payload) {
    try {
      const response = await TechnicalDiagnoseApi.edit(payload.id, payload)
      context.commit("setTechnicalDiagnoses", response.data.technical_diagnoses)
      return true
    } catch (error) {
     //   context.commit("settechnical_diagnoses", [])
      return error
    }
  },

  async delete(context, payload) {
    try {
      const response = await TechnicalDiagnoseApi.delete(payload.id, payload.my_car_id, payload.user_id)
      context.commit("setTechnicalDiagnoses", response.data.technical_diagnoses)
      return true
    } catch (error) {
      //  context.commit("settechnical_diagnoses", [])
      return error
    }
  },

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
