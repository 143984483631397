import Vue from 'vue'
import Vuelidate from 'vuelidate'
import Buefy from 'buefy'
// import 'buefy/dist/buefy.css'
import ApiService from '@/api/api'
import router from './router/router'
import store from './store/store'
import App from './App.vue'

Vue.config.productionTip = false

Vue.use(Vuelidate)
Vue.use(Buefy)

ApiService.init()

new Vue({
  router,
  store,

  created() {
    
  },

  render: h => h(App),
}).$mount('#app')
