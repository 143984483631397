import Vue from "vue"
import Vuex from "vuex"

import auth from "@/store/modules/auth"
import carData from "@/store/modules/car-data"
import clientCars from "@/store/modules/client-cars"
import clientOrders from "@/store/modules/client-orders"
import clientProfile from "@/store/modules/client-profile"
import companyData from "@/store/modules/company-data"
import companyOrders from "@/store/modules/company-orders"
import companyProfile from "@/store/modules/company-profile"
/* import companyServices from "@/store/modules/company-services" */
import appcarsService from "@/store/modules/appcars-service"
import admin from "@/store/modules/admin"
import searchService from "@/store/modules/searchServices"
import serviceCreator from "@/store/modules/serviceCreator"

import insurence from "@/store/modules/insurence"
import technicalDiagnose from "@/store/modules/technicalDiagnose"

import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth: auth,
    carData: carData,
    clientCars: clientCars,
    clientOrders: clientOrders,
    clientProfile: clientProfile,
    companyData: companyData,
    companyOrders: companyOrders,
/*     companyServices: companyServices, */
    companyProfile: companyProfile,
    appcarsService: appcarsService,
    admin: admin,
    searchService: searchService,
    serviceCreator: serviceCreator,
    insurence: insurence,
    technicalDiagnose: technicalDiagnose
  },
  plugins: [createPersistedState()],
})
