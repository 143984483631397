//import Vue from "vue"

import { CompanyService, UserService } from "../../api/api"

const state = {
  users: [],
  companies: [],
  orders: [],
  myCars: [],
  paintColors: [],
  paintTypes: [],
  upholsteryColors: [],
  upholsteryTypes: [],
  rims: [],
  events: [],
}

const getters = {}

const mutations = {
  setUsers(state, users) {
    state.users = users
  },
  setCompanies(state, companies) {
    state.companies = companies
  },
  setOrders(state, orders) {
    state.orders = orders
  },
  setMyCars(state, myCars) {
    state.myCars = myCars
  },
  setPaintColors(state, paintColors) {
    state.paintColors = paintColors
  },
  setPaintTypes(state, paintTypes) {
    state.paintTypes = paintTypes
  },
  setUpholsteryColors(state, upholsteryColors) {
    state.upholsteryColors = upholsteryColors
  },
  setUpholsteryTypes(state, upholsteryTypes) {
    state.upholsteryTypes = upholsteryTypes
  },
  setRims(state, rims) {
    state.rims = rims
  },
  setEvents(state, events) {
    state.events = events
  },
}

const actions = {
  async loadUsers(context) {
    try {
      let token = context.rootState.auth.token
      const response = await UserService.getUsers(token)
      context.commit("setUsers", response.data.content)
    } catch (error) {
      console.error(error)
    }
  },
  async loadCompanies(context, page) {
    try {
      let token = context.rootState.auth.token
      const response = await CompanyService.getCompanies(token, page)
      context.commit("setCompanies", response.data.content.data)
    } catch (error) {
      console.error(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
