/* 
Moduł odpowiedzialny za pobranie danych dotyczących zamówień klienta oraz przechowywanie danych kreatora zamówienia i tworzący to zamówienie
*/
// import ApiService importuje obiekt export default, obiekty podane w klamrach {} importuja obiekty, ktore nie sa export default
import ApiService, { ClientService } from "@/api/api"

const state = {
  orders: [],
  contextOrder: {},
}

const getters = {
  getOrders(state) {
    return state.orders
  },

  getOrderById: state => orderId => {
    return state.orders.data.find(order => order.id === orderId)
  },

  getContextOrder(state) {
    return state.contextOrder
  },
}

const mutations = {
  setOrders(state, orders) {
    state.orders = orders
  },

  setContextOrder(state, order) {
    state.contextOrder = order
  },
}

const actions = {
  async getOrders(context) {
    try {
      const userId = context.rootGetters["auth/id"]
      const ordersRes = await ClientService.getOrders(userId)

      context.commit("setOrders", ordersRes.data.content)
      return ordersRes
    } catch (error) {
      return error.response || error
    }
  },

  async getOrderById(context, orderId) {
    try {
      const ordersRes = await ClientService.getOrderById(orderId)

      context.commit("setContextOrder", ordersRes.data.content)
      return ordersRes
    } catch (error) {
      return error.response || error
    }
  },

  // Akcja do pobierania stron z historią zamówień klienta. API zwraca w komponencie
  // OrdersListing adresy URL do konkretnych stron, które to są podawane do tej akcji jako parametr url
  async getOrdersPage(context, url) {
    try {
      /* 
        Specjalnie dla tej funkcji zaimplementowano funkcję ApiService.getWithSlashEscape.
        Spowodowane jest to tym, że ApiService.get dodaje na końcu URL znak "/", przez co endpoint nie zwracał prawidłowych danych.
        Zmiana implementacji funkcji ApiService.get mogłaby spowodować niedziałanie innych zapytań.
      */
      const ordersPageRes = await ApiService.getWithSlashEscape(url)

      context.commit("setOrders", ordersPageRes.data.content)

      return ordersPageRes
    } catch (error) {
      return error.response || error
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
