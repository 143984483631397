import { ClientService } from "@/api/api"

const state = {
  profile: null,
}

const getters = {
  profile(state) {
    return state.profile
  },

  name(state) {
    if (!state.profile) {
      return ""
    }
    //console.log(state)
    return `${state.profile.details?.first_name || ''} ${state.profile.details?.last_name || ''}`
  },

  address(state) {
    return state.profile.address[0]
  },

  email(state) {
    return state.profile.email
  },

  phone(state) {
    return state.profile?.details?.phone || ''
  },
}

const mutations = {
  setProfile(state, profile) {
    state.profile = profile
  },

  setAddress(state, address) {
    state.profile.address = [address]
  },

  setEmail(state, email) {
    state.profile.email = email
  },

  setPhone(state, phone) {
    state.profile.details.phone = phone
  },
}

const actions = {
  async updateAddress(context, addressForm) {
    try {
      const userId = context.rootGetters["auth/id"]
      const addressRes = await ClientService.updateAddress(userId, addressForm)

      if (addressRes.status === 201) {
        context.commit("setAddress", addressForm.address)
      }

      return addressRes
    } catch (error) {
      return error.response
    }
  },

  async updateEmail(context, email) {
    try {
      const userId = context.rootGetters["auth/id"]
      const emailRes = await ClientService.updateEmail(userId, email)

      if (emailRes.status === 201) {
        context.commit("setEmail", emailRes.data.content.user.email)
      }

      return emailRes
    } catch (error) {
      return error.response
    }
  },

  async updatePhoneNumber(context, phoneNumber) {
    try {
      const userId = context.rootGetters["auth/id"]
      const phoneRes = await ClientService.updatePhoneNumber(userId, phoneNumber)

      if (phoneRes.status === 201) {
        context.commit("setPhone", phoneRes.data.content.details.phone)
      }

      return phoneRes
    } catch (error) {
      return error.response
    }
  },

  async changePassword(context, payload) {
    try {
      const response = await ClientService.changePassword(payload)
      return response
    } catch (error) {
      return error
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
